import React from 'react';
import '../../styles/about/aboutUs.scss';

const brian = require('../../assets/about/brianHeadshot.webp');
const justin = require('../../assets/about/justinHeadshot.webp');
const murtaza = require('../../assets/about/murtazaHeadshot.webp');

const AboutUs = () => {
    return (
        <div id='about-us-layout'>
            <h1 id='about-us-title'>About Us</h1>
            <section id='contact-section'>
                <section id='brian' className='about-us-name-sections'>
                    <img id='brian-headshot' alt='' className='headshot' src={brian}></img>
                    <h1 id='brian-name' className='owner-name'>Brian Coombs</h1>
                    <a className='owner-email' href='mailto:brian@dothouse.group'>brian@dothouse.group</a>
                </section>
                {/* <section id='justin' className='about-us-name-sections'>
                    <img id='justin-headshot' alt='' className='headshot' src={justin}></img>
                    <h1 id='justin-name' className='owner-name'>Justin Goodwin</h1>
                    <a className='owner-email' href='mailto:justin@dothouse.group'>justin@dothouse.group</a>
                </section> */}
                <section id='murtaza' className='about-us-name-sections'>
                    <img id='murtaza-headshot' alt='' className='headshot' src={murtaza}></img>
                    <h1 id='murtaza-name' className='owner-name'>Murtaza Hakimi</h1>
                    <a className='owner-email' href='mailto:murtaza@dothouse.group'>murtaza@dothouse.group</a>
                </section>
            </section>
            <p id='about-us-text'>We're an Austin-based startup founded by a group of individuals who understand the challenges of relocating to a new city and living with strangers. That's why we created Colive.House, a platform designed to simplify the coliving experience and foster a sense of community.<br /> <br />Our coliving spaces offer a unique opportunity to quickly connect with like-minded individuals from your workplace, eliminating the typical hassles associated with finding furnished accommodations, unknown roommates, or navigating complex lease agreements.<br /> <br />We trust that your stay at one of our houses will be both enjoyable and enriching. If you have any inquiries, please don't hesitate to reach out to us via email at <a className='email-link' href='mailto:info@dothouse.group'>info@dothouse.group</a> if you have any questions!</p>
        </div>
    );
}


export default AboutUs;